import React from "react";

export default function Logo({ fill }) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 249.36 185.8"
      xmlns="http://www.w3.org/2000/svg"
      height="35px"
    >
      <path
        transform="translate(-3.32 -36.46)"
        d="M246.8,141c-34.51,11.31-66.88-13.28-65.2-49.57.29-6.33-8.83-5.93-9.6,0l-1.69,13Q168.7,91,167.85,77.47a275.11,275.11,0,0,0-1-33c-.54-6.05-9.57-6.28-9.6,0q-.08,16.75,1,33.39c-.16,5.18-.43,10.37-1,15.55-.87,8.69-1.74,18.55-4.43,27q-3.7,17.74-16.76-5.79l2.93-11.75c1.52-6.08-7.15-8.28-9.26-2.55-2,5.5-4,11.66-4.41,17.68l-1,3.94c-11.29-6.79-24.79-1-29.21,10.46a36.49,36.49,0,0,1-4.28-9.88,27.43,27.43,0,0,0-1.52-9.16c-1.58-4.88-9.89-4.38-9.43,1.28,0,.47.12.9.16,1.36l-.87,8.6a16.51,16.51,0,0,0-9.61-.71C63,94.83,50.42,67.3,31.49,44.8,18.23,29,2.13,37.07,3.39,56.78c1.82,28.58,20.25,57.84,38.82,78.5,3.58,4,10.77-1.37,7.54-5.82l-5.56-7.65a4.93,4.93,0,0,0-2.2-1.72A146.08,146.08,0,0,1,15.56,68.31c-.72-2.62-4.69-14.4-2.72-15.77,6.07-4.22,15.32,3.67,18.24,8A204.15,204.15,0,0,1,46.3,87.27a204.46,204.46,0,0,1,14.34,41.3,32.19,32.19,0,0,0-9.87,15.56c-7.17,25.24-.55,53.49,11.92,75.84,1.91,3.43,8,3,8.77-1.15a231,231,0,0,0-.05-86.73c2.32-.23,5,.82,8.08,3.68a4.92,4.92,0,0,0,6.69.07,51.43,51.43,0,0,0,9.33,11.94c3.59,3.45,9-.06,8-4.67Q112,119.61,123.81,134c1.68,2,4.61,1.72,6.46.21.14.17.26.34.41.51,5.72,6.37,14.54,8.36,21.85,3.55,5.74-3.77,8.66-10.42,10.39-17.48.95,6.24,2,12.47,3.17,18.68,1.11,5.81,8.8,3.59,9.43-1.28l2.29-17.61C190,146,219.37,160.06,249.36,150.23,255.2,148.31,252.7,139,246.8,141Zm-183.71-.29a210.51,210.51,0,0,1,1.43,60.83,93.13,93.13,0,0,1-6.59-28.58C57.58,167.48,58.75,151,63.09,140.68Z"
        fill={fill}
      />
    </svg>
  );
}
